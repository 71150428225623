import images from "~/assets/images";
import "./index.css";

const Logo = () => (
  <svg
    viewBox="295.727 198.561 147.418 115.141"
    xmlns="http://www.w3.org/2000/svg"
    className="logo"
  >
    <g
      id="g-1"
      data-name="Layer 2"
      transform="matrix(1, 0, 0, 1, 300.911316, 202.377213)"
    >
      <g>
        <path d="M 96.8 71.334 L 32.573 4.182 L 20.296 4.173 L 3.511 33.142 L 68.905 101.97 C 69.355 100.496 84.664 86.732 133.079 33.039 L 114.935 4.179 L 67.253 4.01" />
      </g>
    </g>
  </svg>
);

const AnimatedLogo = () => (
  <div className="run-animation remove min-w-[90px]">
    <Logo />
  </div>
);

const LogoOverlay = () => (
  <div className="h-screen flex flex-col justify-center items-center bg-neutral-800">
    <AnimatedLogo />
  </div>
);

export { Logo, AnimatedLogo, LogoOverlay };
