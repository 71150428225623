import { Anchor, Button, Col, Divider, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatedLogo, Logo, LogoOverlay } from "~/components/Logo";
import { Row } from "antd";
import "./index.css";
import {
  GithubOutlined,
  LinkedinOutlined,
  CaretRightOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Highlight, SectionHeader } from "~/components/Text";
import images from "~/assets/images";
import { useWindowDimensions } from "~/utils/hooks";

const NAV_MENU = [
  { label: "About", anchor: "#about-section" },
  { label: "Experience", anchor: "#experience-section" },
  { label: "Portfolio", anchor: "#portfolio-section" },
  { label: "Contact", anchor: "#contact-section" },
];

const LEFT_MENU = [
  { icon: <GithubOutlined />, link: "https://github.com/chingvern" },
  {
    icon: <LinkedinOutlined />,
    link: "https://www.linkedin.com/in/ching-vern-472b68188/",
  },
];

const BANNER_TEXT = [
  "Web Developer.",
  "Mobile Developer.",
  "UI/UX Designer.",
  "Technical Product Manager.",
];

const TECHNOLOGIES = [
  "React",
  "React Native",
  "JavaScript (ES6+)",
  "Django",
  "TypeScript",
  "Python",
];

const WORK_EXPERIENCE = [
  {
    label: <span className="code-font">ARB Axflix</span>,
    key: "tab-1",
    children: (
      <div className="space-y-1">
        <div className="text-base font-semibold text-[var(--font1)]">
          Software Manager <Highlight>@ ARB Axflix Sdn Bhd</Highlight>
        </div>
        <div className="code-font text-[var(--font1)]">Mar 2021 - Present</div>
        <div className="text-[var(--font2)]">
          <ul className="space-y-2">
            <li>
              Worked closely with clients and cross-functional departments to
              communicate project statuses and proposals
            </li>
            <li>
              Managed and led team through all stages of software development
              lifecycle towards successful project delivery
            </li>
            <li>
              Facilitated weekly scrum meetings, sprint planning, sprint reviews
              and sprint prospectives
            </li>
            <li>
              Maintained high standards of software quality within the team by
              establishing good coding practices and habits
            </li>
            <li>
              Managed code builds over multiple test and production environments
            </li>
            <li>
              Assisted in backend development (database design and
              implementation of APIs using <Highlight>Django</Highlight>)
            </li>
            <li>
              Participated in the hiring of junior software engineers and yearly
              performance review of team members
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    label: <span className="code-font">Axflix Tech</span>,
    key: "tab-2",
    children: (
      <div className="space-y-1">
        <div className="text-base font-semibold text-[var(--font1)]">
          Software Engineer <Highlight>@ Axflix Technologies Sdn Bhd</Highlight>
        </div>
        <div className="code-font text-[var(--font1)]">Aug 2019 - Feb 2021</div>
        <div className="text-[var(--font2)]">
          <ul className="space-y-2">
            <li>
              Developed flowcharts, layouts and documentation to identify
              requirements and solutions
            </li>
            <li>
              Designed UI/UX for multiple projects based on requirements given
              using <Highlight>Figma</Highlight> and{" "}
              <Highlight>Adobe XD</Highlight>
            </li>
            <li>
              Developed entire web applications using{" "}
              <Highlight>ReactJS</Highlight> and mobile applications using{" "}
              <Highlight>React Native</Highlight>
            </li>
            <li>
              Published several mobile applications onto iOS and Google Play app
              store
            </li>
            <li>
              Participated in the troubleshooting, debugging and upgrading of
              existing systems
            </li>
          </ul>
        </div>
      </div>
    ),
  },
];

// scroll direction hook
const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<string>("");

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
};

const onClickResume = () => {
  window.open("/assets/resume_cv.pdf", "_blank");
};

const Header = () => {
  const scrollDirection = useScrollDirection();

  return (
    <div
      className={`hidden md:block sticky ${
        scrollDirection === "down" ? "-top-24" : "top-0"
      } h-24 transition-all duration-500 bg-neutral-900 z-10`}
    >
      <Row className="flex py-2 px-8 space-x-5 justify-between min-h-[90px] items-center text-[var(--font1)]">
        <div className="w-[50px]">
          <Logo />
        </div>

        <div className="flex space-x-5 items-center">
          {NAV_MENU.map((menu, index) => (
            <Anchor
              affix={false}
              items={[
                {
                  key: "1",
                  href: menu.anchor,
                  title: (
                    <div
                      className="line-down code-font"
                      style={{ animationDelay: `${index * 100}ms` }}
                    >
                      <Highlight>0{index + 1}. </Highlight>
                      {menu.label}
                    </div>
                  ),
                },
              ]}
            />
          ))}
          <Button
            type="primary"
            ghost
            className="line-down code-font"
            style={{ animationDelay: `${NAV_MENU.length * 100}ms` }}
            onClick={onClickResume}
          >
            Resume
          </Button>
        </div>
      </Row>
    </div>
  );
};

const Landing = () => {
  const { width: screenWidth } = useWindowDimensions();

  const [showLogo, setShowLogo] = useState<boolean>(true);
  const [bannerText, setBannerText] = useState<string>("");
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLogo(false);
    }, 3000);
  });

  useEffect(() => {
    if (!showLogo) {
      wordflick();
    }
  }, [showLogo]);

  const wordflick = () => {
    var part,
      i = 0,
      offset = 0,
      len = BANNER_TEXT.length,
      forwards = true,
      skip_count = 0,
      skip_delay = 15,
      speed = 70;

    setInterval(() => {
      if (forwards) {
        if (offset >= BANNER_TEXT[i].length) {
          ++skip_count;
          if (skip_count == skip_delay) {
            forwards = false;
            skip_count = 0;
          }
        }
      } else {
        if (offset == 0) {
          forwards = true;
          i++;
          offset = 0;
          if (i >= len) {
            i = 0;
          }
        }
      }
      part = BANNER_TEXT[i].substr(0, offset);
      if (skip_count == 0) {
        if (forwards) {
          offset++;
        } else {
          offset--;
        }
      }
      setBannerText(part);
    }, speed);
  };

  if (showLogo) {
    return <LogoOverlay />;
  }

  return (
    <>
      <div className="min-h-screen w-full bg-neutral-900 text-neutral-300">
        <Header />

        {/* LEFT BANNER */}
        <div className="hidden md:flex fixed bottom-0 flex-col items-center px-2 min-w-[90px] text-[var(--font2)]">
          <div className="space-y-4">
            {LEFT_MENU.map((menu) => (
              <div className="text-2xl hover:animate-bounce">
                <Link
                  className="no-underline text-[var(--font2)] hover:text-[var(--primary)]"
                  to={menu.link}
                  target="_blank"
                >
                  {menu.icon}
                </Link>
              </div>
            ))}
          </div>
          <Divider
            type="vertical"
            className="h-[100px] border-[var(--font2)] mt-10"
          />
        </div>

        {/* RIGHT BANNER */}
        <div className="hidden md:flex fixed bottom-0 right-0 flex-col items-center px-2 min-w-[95px] text-[var(--font2)]">
          <Link
            className="no-underline text-[var(--font2)] hover:text-[var(--primary)] transition duration-700 ease-in-out"
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:chingvern9@gmail.com";
              e.preventDefault();
            }}
          >
            <div className="text-vertical code-font">chingvern9@gmail.com</div>
          </Link>
          <Divider
            type="vertical"
            className="h-[100px] border-[var(--font2)] mt-10 mr-3"
          />
        </div>

        {/* CONTENT */}
        <div className="overflow-auto">
          {/* BANNER */}
          <div className="h-screen overflow-hidden flex justify-center md:mt-[-90px] items-center">
            <Row className="w-full md:w-[calc(100%-180px)] px-10 md:px-16">
              <Col>
                <div className="space-y-8">
                  <div className="code-font">
                    <Highlight>Hello, my name is</Highlight>
                  </div>
                  <div className="space-y-4">
                    <div className="text-6xl font-bold text-[var(--font1)]">
                      Ching Vern
                    </div>
                    <div className="text-6xl font-bold text-[var(--font2)]">
                      {bannerText}
                      <span className="blink">{"| "}</span>
                    </div>
                  </div>
                  <div className="text-lg text-[var(--font2)]">
                    As a software engineer specialising in web and mobile
                    development using <Highlight>React</Highlight> and{" "}
                    <Highlight>React Native</Highlight>, I am passionate about
                    delivering the best possible user experience within web and
                    mobile applications.
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* OTHER PAGES */}
          <div className="flex justify-center">
            <div className="w-full md:w-[calc(100%-180px)]">
              <Row id="about-section" className="p-16">
                <SectionHeader index="01." title="About Me" />
                <div className="text-base text-[var(--font2)]">
                  <p>
                    As a software engineer with{" "}
                    <Highlight>over 4 years</Highlight> of experience
                    specialising in <Highlight>front end</Highlight>{" "}
                    development, I am a highly skilled and adaptable developer
                    with a strong focus on delivering quality products.
                  </p>
                  <p>
                    My expertise lies in building{" "}
                    <Highlight>web and mobile</Highlight> applications using the
                    latest technologies and industry best practices. Having been
                    appointed as a <Highlight>team lead</Highlight> in my
                    present organization, I have a proven track record of
                    working effectively in both individual and team settings,
                    collaborating with cross-functional teams to solve complex
                    problems and deliver projects on time and within budget.
                  </p>
                  <p>
                    With a passion for staying up-to-date with emerging
                    technologies and a dedication to delivering exceptional
                    results, I am confident in my ability to make a valuable
                    contribution to any organization.
                  </p>
                  <p>
                    Here are a few technologies I’ve been working with recently:
                  </p>

                  <ul className="text-[var(--font1)]">
                    <Row>
                      {TECHNOLOGIES.map((item) => (
                        <Col xs={24} md={12}>
                          <li>
                            <span className="code-font">{item}</span>
                          </li>
                        </Col>
                      ))}
                    </Row>
                  </ul>
                </div>
              </Row>

              <Row id="experience-section" className="p-16" justify={"center"}>
                <Col xs={24} lg={18}>
                  <SectionHeader index="02." title="Where I've Worked" />
                  <div className="px-2 py-10">
                    <Tabs
                      tabPosition={screenWidth < 768 ? "top" : "left"}
                      items={WORK_EXPERIENCE}
                    />
                  </div>
                </Col>
              </Row>

              <Row
                id="portfolio-section"
                className="p-16 text-[var(--font2)]"
                gutter={[0, 36]}
              >
                <SectionHeader index="03." title="My Projects" />
                <Row align="middle" className="lg:p-11 mt-4">
                  <Col xs={24} lg={12}>
                    <img
                      className="w-full lg:w-[120%]"
                      src={images.tradveller1}
                    />
                  </Col>
                  <Col
                    xs={24}
                    lg={12}
                    className="flex flex-col lg:items-end space-y-2 my-6 lg:my-0"
                  >
                    <div className="code-font">
                      <Highlight>Side Project</Highlight>
                    </div>
                    <div className="text-2xl font-bold text-[var(--font1)]">
                      TheTradveller
                    </div>
                    <div className="bg-neutral-800 px-7 py-7 !mt-6 !mb-4 lg:text-end leading-relaxed shadow-md shadow-[#1a1a1a]">
                      A <Highlight>on demand video</Highlight> and{" "}
                      <Highlight>live streaming</Highlight> platform dedicated
                      to providing high-quality lessons on cryptocurrency
                      trading. With a focus on education, users can learn how to
                      trade like a pro with expert-led lessons and real-time
                      market insights.
                    </div>
                    <div
                      className="flex flex-wrap code-font tracking-tight"
                      style={{ wordSpacing: "-4px" }}
                    >
                      {[
                        "ReactJS",
                        "Antd",
                        "TailwindCSS",
                        "Django",
                        "Oracle Cloud",
                        "Stripe",
                      ].map((item) => (
                        <div className="mx-2 my-1">{item}</div>
                      ))}
                    </div>
                    <div>
                      <Button
                        type="link"
                        onClick={() => {
                          window.open(
                            "https://www.thetradveller.com/home",
                            "_blank"
                          );
                        }}
                      >
                        <SelectOutlined />
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row align="middle" className="lg:p-11">
                  <Col
                    xs={{ order: 2, span: 24 }}
                    lg={{ order: 1, span: 12 }}
                    className="flex flex-col space-y-2 z-[10] my-6 lg:my-0"
                  >
                    <div className="code-font">
                      <Highlight>Side Project</Highlight>
                    </div>
                    <div className="text-2xl font-bold text-[var(--font1)]">
                      KHLC
                    </div>
                    <div className="bg-neutral-800 px-7 py-7 !mt-6 !mb-4 leading-relaxed shadow-md shadow-[#1a1a1a]">
                      A <Highlight>company website</Highlight> showcasing an
                      auditorial firm's expertise and services. Offering
                      comprehensive financial reporting, auditing, and
                      consulting services, the website presents the firm's
                      capabilities and highlights their successful track record.
                    </div>
                    <div
                      className="flex flex-wrap code-font tracking-tight"
                      style={{ wordSpacing: "-4px" }}
                    >
                      {["HTML", "Bootstrap", "JavaScript", "Django"].map(
                        (item) => (
                          <div className="mx-2 my-1">{item}</div>
                        )
                      )}
                    </div>
                    <div>
                      <Button
                        type="link"
                        onClick={() => {
                          window.open("https://www.khlc.com.my/", "_blank");
                        }}
                      >
                        <SelectOutlined />
                      </Button>
                    </div>
                  </Col>
                  <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 12 }}>
                    <div className="w-full lg:w-[120%] lg:ml-[-20%]">
                      <img className="w-full" src={images.khlc1} />
                    </div>
                  </Col>
                </Row>

                <Row align="middle" className="lg:p-11">
                  <Col xs={24} lg={12}>
                    <img
                      className="w-full lg:w-[120%]"
                      src={images.directedu1}
                    />
                  </Col>
                  <Col
                    xs={24}
                    lg={12}
                    className="flex flex-col lg:items-end space-y-2 my-6 lg:my-0"
                  >
                    <div className="code-font">
                      <Highlight>Work Project</Highlight>
                    </div>
                    <div className="text-2xl font-bold text-[var(--font1)]">
                      DirectEdu
                    </div>
                    <div className="bg-neutral-800 px-7 py-7 !mt-6 !mb-4 lg:text-end leading-relaxed shadow-md shadow-[#1a1a1a]">
                      This mobile app complements a{" "}
                      <Highlight>school management system</Highlight>, providing
                      access to student and staff data on-the-go. With features
                      such as attendance tracking, grade reporting, and
                      communication tools, the app streamlines daily school
                      operations and enhances collaboration.
                    </div>
                    <div
                      className="flex flex-wrap code-font tracking-tight"
                      style={{ wordSpacing: "-4px" }}
                    >
                      {["React Native", "CodePush", "Firebase", "RealmJS"].map(
                        (item) => (
                          <div className="mx-2 my-1">{item}</div>
                        )
                      )}
                    </div>
                    <div>
                      <Button
                        type="link"
                        onClick={() => {
                          window.open(
                            "https://apps.apple.com/my/app/directedu/id1523249684",
                            "_blank"
                          );
                        }}
                      >
                        <SelectOutlined />
                      </Button>
                    </div>
                  </Col>
                </Row>

                {showMore && (
                  <>
                    <Row align="middle" className="lg:p-11">
                      <Col
                        xs={{ order: 2, span: 24 }}
                        lg={{ order: 1, span: 12 }}
                        className="flex flex-col space-y-2 z-[10] my-6 lg:my-0"
                      >
                        <div className="code-font">
                          <Highlight>Work Project</Highlight>
                        </div>
                        <div className="text-2xl font-bold text-[var(--font1)]">
                          Cloud Cosec
                        </div>
                        <div className="bg-neutral-800 px-7 py-7 !mt-6 !mb-4 leading-relaxed shadow-md shadow-[#1a1a1a]">
                          This website simplifies{" "}
                          <Highlight>company secretarial</Highlight> processes
                          by providing access to customizable document templates
                          and secure document archiving. Users can generate
                          documents with ease and ensure compliance with legal
                          requirements for corporate record-keeping.
                        </div>
                        <div
                          className="flex flex-wrap code-font tracking-tight"
                          style={{ wordSpacing: "-4px" }}
                        >
                          {[
                            "ReactJS",
                            "Google Drive",
                            "Google OAuth",
                            "Django",
                            "Stripe",
                          ].map((item) => (
                            <div className="mx-2 my-1">{item}</div>
                          ))}
                        </div>
                        <div>
                          <Button
                            type="link"
                            onClick={() => {
                              window.open(
                                "https://www.linkedin.com/feed/update/urn:li:activity:6891588300474343424/",
                                "_blank"
                              );
                            }}
                          >
                            <SelectOutlined />
                          </Button>
                        </div>
                      </Col>
                      <Col
                        xs={{ order: 1, span: 24 }}
                        lg={{ order: 2, span: 12 }}
                      >
                        <div className="w-full lg:w-[120%] lg:ml-[-20%]">
                          <img className="w-full" src={images.cosec1} />
                        </div>
                      </Col>
                    </Row>

                    <Row align="middle" className="lg:p-11">
                      <Col xs={24} lg={12}>
                        <img
                          className="w-full lg:w-[120%]"
                          src={images.tunetalk1}
                        />
                      </Col>
                      <Col
                        xs={24}
                        lg={12}
                        className="flex flex-col lg:items-end space-y-2 my-6 lg:my-0"
                      >
                        <div className="code-font">
                          <Highlight>Work Project</Highlight>
                        </div>
                        <div className="text-2xl font-bold text-[var(--font1)]">
                          TuneTalk Care
                        </div>
                        <div className="bg-neutral-800 px-7 py-7 !mt-6 !mb-4 lg:text-end leading-relaxed shadow-md shadow-[#1a1a1a]">
                          This mobile app helps organizations{" "}
                          <Highlight>track staff attendance</Highlight> and
                          comply with COVID-19 measures by conducting daily
                          health checks. With user-friendly features for data
                          collection and reporting, the app streamlines HR
                          processes and ensures a safe workplace.
                        </div>
                        <div
                          className="flex flex-wrap code-font tracking-tight"
                          style={{ wordSpacing: "-4px" }}
                        >
                          {["React Native", "Firebase", "Redux"].map((item) => (
                            <div className="mx-2 my-1">{item}</div>
                          ))}
                        </div>
                        <div>
                          <Button
                            type="link"
                            onClick={() => {
                              window.open(
                                "https://apps.apple.com/my/app/tune-talk-care/id1541576563",
                                "_blank"
                              );
                            }}
                          >
                            <SelectOutlined />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    ghost
                    className="!my-10 code-button"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                  >
                    Show {showMore ? "Less" : "More"}
                  </Button>
                </div>
              </Row>

              <Row
                id="contact-section"
                className="p-8 md:p-32 min-h-screen"
                justify={"center"}
                align="middle"
              >
                <Col
                  xs={24}
                  lg={18}
                  className="flex flex-col items-center space-y-4 text-[var(--font2)]"
                >
                  <div className="code-font">
                    <Highlight>04. What's Next?</Highlight>
                  </div>
                  <div className="ml-4 text-5xl font-bold text-[var(--font1)]">
                    Get In Touch
                  </div>
                  <div className="leading-relaxed text-base text-center">
                    Interested in discussing potential job opportunities,
                    exciting projects, or just want to connect professionally?
                    I'm actively exploring new career prospects and would love
                    to hear from you, feel free to drop me a message!
                  </div>
                  <Button
                    type="primary"
                    ghost
                    className="!my-10 code-button"
                    onClick={onClickResume}
                  >
                    Resume
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
