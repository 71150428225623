import React from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Routing from "~/Routing";
import { Button, ConfigProvider, theme } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#fcd34d",
        },
      }}
    >
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
