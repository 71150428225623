import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import Landing from "~/pages/landing";

const Routing = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Landing />} />
    </Routes>
  );
};

export default Routing;
