import { Divider } from "antd";
import { ReactNode } from "react";

type HighlightProps = {
  children?: ReactNode;
};

const Highlight = ({ children }: HighlightProps) => (
  <span className="text-[var(--primary)]">{children}</span>
);

type SectionHeaderProps = {
  index?: string;
  title?: string;
};

const SectionHeader = ({ index, title }: SectionHeaderProps) => (
  <div className="w-full flex items-center space-x-8">
    <div className="flex items-end">
      <span className="text-xl code-font">
        <Highlight>{index}</Highlight>
      </span>
      <span className="ml-3 text-2xl font-bold text-[var(--font1)]">
        {title}
      </span>
    </div>
    <div className="md:min-w-[40%]">
      <Divider className="border-neutral-600" />
    </div>
  </div>
);

export { Highlight, SectionHeader };
