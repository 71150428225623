import directedu1Img from "./projects/directedu1.png";
import tradveller1Img from "./projects/tradveller1.png";
import khlc1Img from "./projects/khlc1.png";
import tunetalk1Img from "./projects/tunetalk1.png";
import cosec1Img from "./projects/cosec1.png";

const images: any = {
  directedu1: directedu1Img,
  tradveller1: tradveller1Img,
  khlc1: khlc1Img,
  tunetalk1: tunetalk1Img,
  cosec1: cosec1Img,
};

export default images;
